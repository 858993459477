<template>
	<div id="warper">
		<el-card class="center">
			<div class="payment">
				<div
					style="color: #101010;font-size: 14px;font-family: SourceHanSansSC-regular;margin-top: 30px;font-weight: bold;margin-bottom: 14px;">
					订单编号：{{orderinfo.payNo}}</div>
				<div
					style="display: flex;padding-bottom: 23px;border-bottom: 1px solid #eee;align-items: center;justify-content: space-between;">
					<div style="color: #101010;font-size: 14px;font-family: SourceHanSansSC-regular;font-weight: bold;">
						支付金额：<span style="font-size: 20px;color:#7ECE64 ;">¥50</span></div>
				</div>
				<div>
					<div class="paytitle"
						style="color: #101010;font-size: 14px;font-family: SourceHanSansSC-regular;margin-top: 38px;font-weight: bold;margin-bottom: 14px;">
						支付方式</div>
					<div class="wxzf">
						<img src="../../assets/img/wx.png"
							style="width: 24px;height: 24px;margin-left: 24px;margin-top: 8px;margin-right: 4px;" />
						<div>微信支付</div>
						<img src="../../assets/img/sanj.png"
							style="width: 20px;height: 21px;position: absolute;right: 0;bottom: 0;" />
						<img src="../../assets/img/diegou.png"
							style="width: 10px;height: 10px;position: absolute;right: 2px;bottom: 2px;" />
					</div>
					<div style="display: flex;margin-top: 14px;">
						<div style="text-align: center;">
							<div style="width: 200px;height: 200px;">
								<span v-if="isLoading" style="color: red;line-height: 200px">等待二维码生成中...</span>
								<img v-else :src="payimg" style="width: 200px;height: 200px;" />
							</div>
							<div
								style="background-color: #62C442;color: #fff;height: 28px;line-height: 28px;text-align: center;">
								请打开手机微信，扫一扫完成支付</div>
							<div @click="ExamSignUpNo()" style="cursor: pointer;">支付不成功，点击刷新</div>
						</div>
						<img src="../../assets/img/shouji.png" style="width: 206px;height: 255px;" />
					</div>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		ExamSignUpInfoBypayNo,
		ExamSignUpNo,
	} from "@/api/user";
	import {
		getwxpay,
		getwxpaylj,
		getpayOrder
	} from "@/api/other";
	import Header from "@/components/header.vue";
	import Footer from "@/components/footer.vue";
	import global from "@/assets/js/globalconfig";
	import axios from "axios";
	export default {
		components: {
			Header,
			Footer,
		},
		data() {
			return {
				baseurl: global.baseurl,
				orderno: "",
				userinfo: "",
				info: {},
				orderinfo: {},
				payimg: '',
				timers: '',
				isLoading: true,
			};
		},
		created() {
			this.orderno = this.$route.query.orderno;
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
			this.ExamSignUpInfoBypayNo();
			console.log(111)
			// setTimeout(() => {
			// 	this.ExamSignUpNo();
			// }, 6000);
			this.timers = setInterval(() => {
				this.ExamSignUpInfoBypayNo();
			}, 10000);
		},
		methods: {
			pay() {
				//预下单
				var _this = this;
				var params = new URLSearchParams();
				params.append('out_trade_no', _this.orderinfo.trade_no);
				getpayOrder(params).then((res) => {
					if (res == "SUCCESS") {
						// 	//跳转到支付完成页面
						_this.orderinfo.payState = 2;
						_this.goto("usercenter", {
							type: 7
						}, 1);
					} else {
						if (_this.payimg == '') {
							_this.getwxpay();
						}
					}
				})
			},
			ExamSignUpNo() {
				var _this = this;
				var params = {
					id: _this.orderinfo.id
				};
				ExamSignUpNo(params).then((res) => {
					_this.orderinfo.trade_no = res;
					_this.orderno = res;
					_this.getwxpay();
				})
				this.isLoading = false;
				// if (this.isRefresh) {
				// 	this.isRefresh = false;

				// 	setTimeout(() => {
				// 		this.isRefresh = true;
				// 	}, 3000);
				// }
			},
			ExamSignUpInfoBypayNo() {
				var _this = this;
				var par = {
					KW: this.orderno
				};
				ExamSignUpInfoBypayNo(par).then((res) => {
					if (res.success) {
						if (res.data.length > 0) {
							_this.orderinfo = res.data[0];
							if (_this.orderinfo.state > 1) {
								console.log(2)
								clearInterval(_this.timers)
								_this.goto("usercenter", {
									type: 7
								}, 1);
							} else {
								_this.pay();
								console.log(this.isLoading)
								if (this.isLoading == true) {
									setTimeout(() => {
										this.ExamSignUpNo();
									}, 3000);
								}
							}
						} else {
							console.log(3)
							clearInterval(_this.timers)
							_this.goto("usercenter", {
								type: 7
							}, 1);
						}
					}else{
						this.$message({
							message: res.msg, //提示的信息
							type: 'error',
							duration: 3000,
							isshow: true,
							onClose: () => {
								this.goto("usercenter", {
									type: 7
								}, 1);
							}
						});
					}
				})
			},
			getwxpay() {
				var _this = this;
				var params = new URLSearchParams();
				params.append('out_trade_no', _this.orderinfo.trade_no);
				params.append('total', 50);
				params.append('notify_url', this.baseurl + '/api/WxPayNotify/Transactions?orderno=' +
					_this
					.orderinfo.payNo);
				params.append('description', "兰溪人才网-招考报名-" + _this.orderinfo.payNo);
				getwxpay(params).then((res) => {
					_this.payimg = res.data;
				})
			},
			onSubmit() {
				console.log('submit!');
			},
			goto(url, data, type = 2) {
				this.resolvewin(url, data, type);
			},
		},
	};
</script>


<style lang="scss" scoped>
	.center {
		width: 1200px;
		margin: 29px auto;
		height: 863px;


		.payment {
			width: 529px;
			margin: 0 auto;




			.wxzf {
				width: 135px;
				height: 40px;
				line-height: 40px;
				display: flex;
				align-content: center;
				text-align: center;
				border: 1px solid #409EFF;
				position: relative;
			}
		}
	}
</style>